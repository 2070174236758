import * as React from "react";
import { Link, graphql } from "gatsby";
import SectionTitle from "../components/SectionTitle";
import {  Grid, Container, Segment } from "semantic-ui-react";
import { MarkdownRemarkConnection } from "../graphql-types";
import {withLayout, LayoutProps} from "../components/Layout";
import JobsList from "../components/JobsList/JobsList";
import ListingPagination from "../components/ListingPagination/ListingPagination";

interface IndexPageProps extends LayoutProps {
  data: {
    jobs: MarkdownRemarkConnection;
  };
  pageContext: {
    tag?: string; // only set into `templates/tags-pages.tsx`
  };
}

const JobsPage = (props: IndexPageProps) => {
  const jobs = props.data.jobs.edges;
  const { pathname } = props.location;
  const pageCount = Math.ceil(props.data.jobs.totalCount / 10);

  const Jobs = (
    <Container style={{marginBottom: "20px"}}>
      <SectionTitle
        title="Jobs"
        subtitle="Jobs hiring from tech right now"
      />
      <JobsList
        jobs={jobs.map((jobEdge) => jobEdge.node)}
      />
    </Container>
  );

  return (
    <Container>
      {/* Content */}
      <Segment vertical>
        <Grid padded style={{ justifyContent: "space-around" }}>
          <Grid.Row>
            <div style={{ maxWidth: 600 }}>
              {Jobs}
            </div>
          </Grid.Row>
          <Grid.Row>
            <ListingPagination Link={Link} pathname={pathname} pageCount={pageCount} />
          </Grid.Row>
        </Grid>

      </Segment>
    </Container>
  );
};

export default withLayout(JobsPage);

export const pageQuery = graphql`
query JobsPage {
  jobs: allMarkdownRemark(
    filter: {
      frontmatter: {
        draft: { ne: true }
        placeholder: { eq: true }
      },
      fileAbsolutePath: {regex: "/\/jobs\//"},
      excerpt: {}
    }
    sort:{ order:DESC, fields:[frontmatter___rank]}
    limit: 10
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          organization
          jobTitle
          location
          url
          tags
          image {
            children {
              ... on ImageSharp {
                fixed(width: 120) {
                	src
            			srcSet
                }
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
